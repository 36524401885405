var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col px-5 pb-10 mx-auto mb-10 text-gray-600 border-b border-gray-200 sm:flex-row"},[_c('div',{staticClass:"flex-grow mt-6 text-left sm:mt-0"},[_c('h1',{staticClass:"mb-3 text-2xl font-bold text-gray-700"},[_vm._v(" "+_vm._s(_vm.vacancy.jobTitle)+" ")]),_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"inline-block mr-2 font-bold"},[_vm._v("Job Grade:")]),_c('span',[_vm._v(_vm._s(_vm.vacancy.jobGrade))])]),_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"inline-block mr-2 font-bold"},[_vm._v("Grade Salary:")]),_c('span',[_vm._v(_vm._s(_vm.vacancy.gradeSalary))])]),_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"inline-block mr-2 font-bold"},[_vm._v("Location:")]),_c('span',[_vm._v(_vm._s(_vm.vacancy.location))])]),_c('h2',{staticClass:"mb-2 text-xl font-bold"},[_vm._v("Job Description")]),_c('p',{staticClass:"leading-relaxed",class:{
        'line-clamp-4':
          _vm.vacancy.jobDescription.trim().split(/\s+/).length > 60
            ? true
            : false,
      }},[_c('VueShowdown',{attrs:{"markdown":`${_vm.vacancy.jobDescription}`}})],1),(
        _vm.vacancy.jobDescription.trim().split(/\s+/).length > 60 ? true : false
      )?_c('router-link',{staticClass:"inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange",attrs:{"to":{
        name: 'SelectedLatestVacancyDetail',
        params: {
          vacancy: _vm.vacancy,
          jobTitle: _vm.vacancy.jobTitle.replace(/\s+/g, '-').toLowerCase(),
        },
      }},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_vm._v(" View job details "),_c('svg',{staticClass:"w-4 h-4 ml-2",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }