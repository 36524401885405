<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div class="px-5 py-20">
      <div v-if="!loading">
        <div class="flex flex-wrap w-full mb-10">
          <div class="w-full mb-6 lg:w-1/2 lg:mb-0">
            <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
              {{ $t("services") }}
            </h1>
            <div class="w-20 h-1 rounded bg-primary-green"></div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-6 pb-20 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
        >
          <div
            class="overflow-hidden text-gray-600 rounded-sm"
            v-for="department in items"
            :key="department.id"
          >
            <img
              class="object-cover object-center w-full md:max-w-sm h-80"
              :src="getBaseUrl() + department.departmentImage.url"
              :alt="department.title"
            />
            <div class="py-4">
              <div class="mb-2 text-xl font-bold">{{ department.title }}</div>
              <p
                class="leading-relaxed"
                :class="{
                  'line-clamp-5':
                    department.departmentContent.trim().split(/\s+/).length > 36
                      ? true
                      : false,
                }"
              >
                <VueShowdown :markdown="department.departmentContent" />
              </p>
              <router-link
                v-if="
                  department.departmentContent.trim().split(/\s+/).length > 36
                "
                @click.native="scrollToTop"
                :to="{
                  name: 'SelectedDepartmentDetail',
                  params: {
                    department: department,
                    title: department.title.replace(/\s+/g, '-').toLowerCase(),
                    id: department.id,
                  },
                }"
                class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
              >
                ...{{ $t("read-more") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Departments",
  data() {
    return {
      items: [],
      more: false,
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDepartments",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
