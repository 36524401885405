<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div v-if="!loading">
      <div class="px-5 py-20 mx-auto">
        <div v-for="vacancy in items" :key="vacancy.id">
          <SingleVacancyDisplay :vacancy="vacancy" />
        </div>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import SingleVacancyDisplay from "@/components/SingleVacancyDisplay.vue";

export default {
  name: "Vacancy",
  components: { SingleVacancyDisplay },
  data() {
    return {
      items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/vacancies`);
      let sorted = response.data.sort(
        (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      );
      this.items = sorted;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
