<template>
  <div v-if="!loading">
    <div class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="flex flex-col pb-10 mx-auto mb-10">
        <div class="inline-flex justify-center flex-shrink-0">
          <img
            class="object-cover object-center w-full h-full rounded"
            :src="getBaseUrl() + department.departmentImage.url"
            alt="samaritan"
          />
        </div>
        <div class="flex-grow mt-6 sm:text-left">
          <div class="mb-2 text-2xl font-bold">{{ department.title }}</div>
          <p class="mt-3 text-base leading-relaxed">
            <VueShowdown :markdown="department.departmentContent" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img :src="loadingImage" class="w-64 m-auto" />
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "SelectedDepartmentDetail",
  data() {
    return {
      loading: true,
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDepartments",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      if (this.$route.params.department === undefined) {
        try {
          const response = await axios.get(this.api);
          this.department = response.data.find(
            (department) =>
              department.title.replace(/\s+/g, "-").toLowerCase() ===
              this.$route.params.title.replace(/\s+/g, "-").toLowerCase()
          );
          this.loading = false;
        } catch (error) {
          this.error = error;
        }
      } else {
        this.department = this.$route.params.department;
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
