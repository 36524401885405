<template>
  <div
    class="flex flex-col pb-10 mx-auto mb-10 text-gray-600 border-b border-gray-200 sm:flex-row"
  >
    <div
      class="inline-flex justify-center flex-shrink-0 sm:w-48 sm:h-48 sm:mr-8"
    >
      <img
        class="object-cover object-center w-full h-full rounded-sm"
        :src="getBaseUrl() + news.newsImage.url"
        alt="samaritan"
      />
    </div>
    <div class="flex-grow mt-6 text-left sm:mt-0">
      <h2 class="text-xl font-semibold text-gray-900">
        {{ news.newsTitle }}
      </h2>
      <span class="text-sm text-gray-500">{{ news.publishedDate }}</span>
      <p
        class="mt-3 leading-relaxed"
        :class="{
          'line-clamp-4':
            news.newsContent.trim().split(/\s+/).length > 60 ? true : false,
        }"
      >
        <VueShowdown :markdown="news.newsContent" />
      </p>
      <p v-if="news.newsSource" class="mt-2">
        <span class="italic font-medium">Source link: </span>
        <a
          :href="news.newsSource"
          target="_blank"
          class="border-b border-transparent hover:text-primary-orange hover:border-primary-orange"
          >{{ news.newsSource }}</a
        >
      </p>
      <router-link
        :to="{
          name: 'SelectedLatestNewsDetail',
          params: {
            news: news,
            newsTitle: news.newsTitle.replace(/\s+/g, '-').toLowerCase(),
          },
        }"
        @click.native="scrollToTop"
        class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
        v-if="news.newsContent.trim().split(/\s+/).length > 60 ? true : false"
      >
        Read More
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="w-4 h-4 ml-2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </router-link>
      <!-- <a v-if="news.newsContent.trim().split(/\s+/).length > 28 ? true : false" class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange">
            <span v-if="showMore">... Read More</span>
            <span v-else>... less</span>
         </a> -->
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
export default {
  name: "SingleNewsDisplay",
  props: ["news"],
  data() {
    return {
      showMore: true,
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
