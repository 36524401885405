import { baseUrl } from "@/Api";
export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem("lang") || "en",
  },
  mutations: {
    SET_LANG(state, payload) {
      state.lang = payload;
    },
  },
  actions: {
    doChangeLanguage({ commit, state }, payload) {
      // state change + localstorage
      commit("SET_LANG", payload);
    },
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    apiDoctors(state) {
      return `${baseUrl}/doctors?_locale=${state.lang}`;
    },
    apiDepartments(state) {
      return `${baseUrl}/departments?_locale=${state.lang}`;
    },

    apiHeros(state) {
      return `${baseUrl}/slider-images?_locale=${state.lang}`;
    },
    apiHospitals(state) {
      return `${baseUrl}/our-hospitals?_locale=${state.lang}`;
    },
    apiCommunity(state) {
      return `${baseUrl}/our-communities?_locale=${state.lang}`;
    },
    apiOurValues(state) {
      return `${baseUrl}/our-values?_locale=${state.lang}`;
    },
    apiPrinciples(state) {
      return `${baseUrl}/principle-developments?_locale=${state.lang}`;
    },
    apiAbouts(state) {
      return `${baseUrl}/abouts?_locale=${state.lang}`;
    },
    apiVissions(state) {
      return `${baseUrl}/vissions?_locale=${state.lang}`;
    },
    apiMissions(state) {
      return `${baseUrl}/missions?_locale=${state.lang}`;
    },
    apiVideos(state) {
      return `${baseUrl}/videos?_locale=${state.lang}`;
    },
    apiFooters(state) {
      return `${baseUrl}/footers?_locale=${state.lang}`;
    },
    apiLatestNews(state) {
      return `${baseUrl}/latest-news?_locale=${state.lang}`;
    },
    // apiSocials(state) {
    //   return `${baseUrl}/socials?_locale=${state.lang}`;
    // },
    // apiImprint(state) {
    //   return `${baseUrl}/imprints?_locale=${state.lang}`;
    // },
    // apiPrivacies(state) {
    //   return `${baseUrl}/privacies?_locale=${state.lang}`;
    // },
  },
};
