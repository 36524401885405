<template>
  <div class="px-5 py-20">
    <div class="flex flex-wrap md:flex-nowrap" v-for="m in mission" :key="m.id">
      <div class="flex flex-col flex-shrink-0 mb-6 md:w-64 md:mb-0">
        <span class="text-2xl font-bold text-gray-900 sm:text-3xl">
          {{ m.Title }}
        </span>
      </div>
      <div class="md:flex-grow">
        <p class="leading-relaxed">
          <VueShowdown :markdown="m.Content" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Mission",
  data() {
    return {
      mission: [],
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiMissions",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.mission = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
