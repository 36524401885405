<template>
  <div id="app">
    <!-- :class="{ scrolled: !view.atTopOfPage }" -->
    <div id="nav" class="sticky top-0 z-50">
      <Header />
    </div>
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // view: {
      //    atTopOfPage: true,
      // },
    };
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // background-color: #f2f2f2;
}

#nav {
  // padding: 30px;

  a {
    // font-weight: bold;
    // color: #2c3e50;

    &.router-link-exact-active {
      // color: #42b983;
      color: #4aa350;
    }
  }
}
.scrolled {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-bottom: 0px;
}
</style>
