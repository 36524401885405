var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"max-w-6xl mx-auto text-gray-600"},[_c('div',{staticClass:"px-5 py-20"},[(!_vm.loading)?_c('div',[_c('div',{staticClass:"flex flex-wrap w-full mb-10"},[_c('div',{staticClass:"w-full mb-6 lg:w-1/2 lg:mb-0"},[_c('h1',{staticClass:"mb-2 text-2xl font-bold text-gray-900 sm:text-3xl"},[_vm._v(" "+_vm._s(_vm.$t("services"))+" ")]),_c('div',{staticClass:"w-20 h-1 rounded bg-primary-green"})])]),_c('div',{staticClass:"grid grid-cols-1 gap-6 pb-20 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"},_vm._l((_vm.items),function(department){return _c('div',{key:department.id,staticClass:"overflow-hidden text-gray-600 rounded-sm"},[_c('img',{staticClass:"object-cover object-center w-full md:max-w-sm h-80",attrs:{"src":_vm.getBaseUrl() + department.departmentImage.url,"alt":department.title}}),_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"mb-2 text-xl font-bold"},[_vm._v(_vm._s(department.title))]),_c('p',{staticClass:"leading-relaxed",class:{
                'line-clamp-5':
                  department.departmentContent.trim().split(/\s+/).length > 36
                    ? true
                    : false,
              }},[_c('VueShowdown',{attrs:{"markdown":department.departmentContent}})],1),(
                department.departmentContent.trim().split(/\s+/).length > 36
              )?_c('router-link',{staticClass:"inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange",attrs:{"to":{
                name: 'SelectedDepartmentDetail',
                params: {
                  department: department,
                  title: department.title.replace(/\s+/g, '-').toLowerCase(),
                  id: department.id,
                },
              }},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_vm._v(" ..."+_vm._s(_vm.$t("read-more"))+" ")]):_vm._e()],1)])}),0)]):_c('div',[_c('img',{staticClass:"w-64 m-auto",attrs:{"src":_vm.loadingImage}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }