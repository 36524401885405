<template>
  <section>
    <div v-if="!loading" class="max-w-6xl mx-auto text-gray-600">
      <div class="px-5 py-20" v-for="about in items" :key="about.id">
        <div class="w-full mb-10">
          <h1
            class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl title-font"
          >
            {{ about.aboutname }}
          </h1>
          <div class="w-20 h-1 rounded bg-primary-green"></div>
        </div>
        <div>
          <p class="text-lg leading-relaxed">
            <VueShowdown :markdown="`${about.content}`" />
          </p>
        </div>
      </div>
      <div class="px-5">
        <OurValues></OurValues>
        <Principles></Principles>
        <Vission></Vission>
        <Mission></Mission>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../Api";
import OurValues from "../components/OurValues.vue";
import Principles from "../components/PrinciplesOfDevelopment.vue";
import Vission from "../components/Vission.vue";
import Mission from "../components/Mission.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",
  components: {
    OurValues,
    Principles,
    Vission,
    Mission,
  },
  data() {
    return {
      items: {},
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiAbouts",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
