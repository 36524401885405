<template>
  <section class="relative max-w-6xl px-5 py-20 mx-auto text-gray-600">
    <div class="flex flex-wrap w-full mb-10">
      <div class="flex flex-col w-full lg:w-1/2 lg:mb-0">
        <h1
          class="mb-2 text-2xl font-medium text-gray-900 sm:text-3xl title-font"
        >
          {{ $t("our-hospital-is-24-hours-open") }}
        </h1>
        <div class="flex space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            viewBox="0 0 20 20"
            fill="#4AA350"
          >
            <path
              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
            />
          </svg>
          <span class="font-semibold">+251 94 444 4801</span>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap mx-auto">
      <div
        class="relative flex items-end justify-start w-full p-10 overflow-hidden bg-gray-300 rounded-sm h-96 lg:h-auto lg:w-3/5"
      >
        <iframe
          width="100%"
          height="100%"
          class="absolute inset-0"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=Samaritan%20Surgical%20center%20%E1%88%B3%E1%88%9B%E1%88%AA%E1%89%B3%E1%8A%95%20%E1%8B%A8%E1%89%80%E1%8B%B6%20%E1%88%85%E1%8A%AD%E1%88%9D%E1%8A%93%20%E1%88%9B%E1%8B%95%E1%8A%A8%E1%88%8D,%20Meri%20Lukie,%20Addis%20Ababa,%20Ethiopia+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ><a href="https://www.gps.ie/wearable-gps/"
            >wearable trackers</a
          ></iframe
        >
        <!-- <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Samaritan%20Surgical%20center%20%E1%88%B3%E1%88%9B%E1%88%AA%E1%89%B3%E1%8A%95%20%E1%8B%A8%E1%89%80%E1%8B%B6%20%E1%88%85%E1%8A%AD%E1%88%9D%E1%8A%93%20%E1%88%9B%E1%8B%95%E1%8A%A8%E1%88%8D+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
           </iframe> -->
      </div>
      <div
        class="flex flex-col w-full mt-8 bg-white lg:pl-10 lg:w-2/5 md:py-8 lg:py-0 md:mt-0 sm:w-4/5 sm:mx-auto"
      >
        <h2 class="mb-1 text-2xl font-bold text-gray-900">
          {{ $t("contact-us") }}
        </h2>
        <p class="mb-3 font-semibold leading-relaxed">
          {{ $t("leave-us-a-message-here") }}
        </p>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div class="relative mb-4">
              <label
                for="name"
                class="block mb-1 text-sm font-semibold text-gray-700"
              >
                {{ $t("name") }}
              </label>
              <ValidationProvider
                name="Name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  id="name"
                  v-model="name"
                  name="name"
                  :placeholder="$t('name')"
                  class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label
                for="email"
                class="block mb-1 text-sm font-semibold text-gray-700"
              >
                {{ $t("email") }}</label
              >
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  name="email"
                  :placeholder="$t('email')"
                  class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label
                for="message"
                class="block mb-1 text-sm font-semibold text-gray-700"
              >
                {{ $t("message") }}</label
              >
              <ValidationProvider
                name="Message"
                rules="required"
                v-slot="{ errors }"
              >
                <textarea
                  id="message"
                  v-model="message"
                  name="message"
                  :placeholder="$t('message')"
                  class="w-full h-32 px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                ></textarea>
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <button
              loading="true"
              type="submit"
              value="send"
              class="w-full px-6 py-3 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-primary-orange focus:outline-none hover:bg-yellow-700"
            >
              {{ $t("send-message") }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import emailjs from "emailjs-com";
import { baseUrl } from "../Api";

export default {
  name: "Contact",
  data: () => ({
    items: [],
    valid: true,
    name: "",
    email: "",
    message: "",
    loading: false,
    snackbar: false,
    snackbar_message: "",
    error: "",
  }),
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/contacts`);
      this.items = response.data;
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
    showToast() {
      this.$toast.success("Message sent sucessfully");
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(
              "https://aem.adeymeselesh.de/api/v1/organization/api_sender",
              {
                from: "dev@adeymailserver.de",
                to: "dev@adeymailserver.de",
                // cc: "niguszinie@gmail.com",
                // bcc: "hailu1221tedros@gmail.com",
                subject: "Message from " + this.email,
                text: this.message,
              }
            )
            .then((response) => {
              this.showToast();
              // console.log("response", response);
              if (!response.data.error) {
                axios.post(
                  "https://aem.adeymeselesh.de/api/v1/organization/api_sender",
                  {
                    from: "dev@adeymailserver.de",
                    to: this.email,
                    // cc: "niguszinie@gmail.com",
                    // bcc: "hailu1221tedros@gmail.com",
                    subject: "Thanks",
                    text: "Thanks for contacting us",
                  }
                );
              }
            });
          // emailjs.sendForm(
          //   "service_zlnw8io",
          //   "template_b0f7soj",
          //   e.target,
          //   "user_pHlXRSBk1ewiAuGzuc7jE"
          // );
          // // this.$nextTick(() => {
          // //    this.$refs.form.reset();
          // //    this.name = "";
          // //    this.email = "";
          // //    this.message = "";

          // // });
        } catch (error) {
          this.error = error;
        }
      } else {
        console.log("Couldn't post email");
      }
    },
  },
};
</script>

<style></style>
