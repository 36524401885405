<template>
  <div>
    <section class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="flex flex-wrap w-full mb-6">
        <div class="w-full mx-auto md:w-2/5 lg:mb-0">
          <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
            {{ $t("nav.reviews") }}
          </h1>
          <div class="w-20 h-1 rounded bg-primary-green"></div>
        </div>
      </div>
      <div class="w-full mx-auto md:w-2/5 md:mt-0">
        <p class="mb-3 font-semibold leading-relaxed">
          {{ $t("leave-us-a-review-here") }}
        </p>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div class="relative mb-4">
              <label
                for="name"
                class="block mb-1 text-sm font-semibold text-gray-700"
              >
                {{ $t("full-name") }}
              </label>
              <ValidationProvider
                name="Name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  id="name"
                  v-model="fullName"
                  name="name"
                  :placeholder="$t('full-name')"
                  class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label
                for="email"
                class="block mb-1 text-sm font-semibold text-gray-700"
                >{{ $t("email") }}</label
              >
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  name="email"
                  :placeholder="$t('email')"
                  class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label
                for="message"
                class="block mb-1 text-sm font-semibold text-gray-700"
                >{{ $t("message") }}</label
              >
              <ValidationProvider
                name="Message"
                rules="required"
                v-slot="{ errors }"
              >
                <textarea
                  id="message"
                  v-model="message"
                  name="message"
                  :placeholder="$t('message')"
                  class="w-full h-32 px-3 py-3 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                ></textarea>
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <button
              loading="true"
              type="submit"
              value="send"
              class="w-full px-6 py-3 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-primary-orange focus:outline-none hover:bg-yellow-700"
            >
              {{ $t("send-review") }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </section>
    <section class="max-w-6xl px-5 mx-auto text-gray-600">
      <div
        class="grid grid-cols-1 gap-5 pb-20 mx-auto mt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div v-for="review in publicReview" :key="review.id">
          <div class="p-4 bg-gray-100 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="block w-5 h-5 mb-4 text-gray-400"
              viewBox="0 0 975.036 975.036"
            >
              <path
                d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zm-819 856.3c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
              />
            </svg>
            <p class="mb-4">
              {{ review.Content }}
            </p>
            <div class="inline-flex items-center">
              <span class="flex flex-col flex-grow">
                <span class="font-semibold text-gray-700 capitalize">
                  {{ review.fullName }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Reviews",
  data() {
    return {
      fullName: "",
      email: "",
      message: "",
      reviews: [],
      snackbar: false,
      snackbar_message: "",
      error: "",
    };
  },
  computed: {
    publicReview() {
      return this.reviews.filter(function (item) {
        return item.Public === true;
      });
    },
  },
  async mounted() {
    try {
      const reviews = await axios.get(`${baseUrl}/reviews`);
      this.reviews = reviews.data;
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
    showToast() {
      this.$toast.success("Review sent sucessfully");
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(`${baseUrl}/reviews`, {
              fullName: this.fullName,
              Email: this.email,
              Content: this.message,
            })
            .then(function (response) {
              console.log(response.status);
            })
            .catch(function (error) {
              this.error = error;
            });
          this.$nextTick(() => {
            this.$refs.form.reset();
            this.fullName = "";
            this.email = "";
            this.message = "";
            this.showToast();
          });
        } catch (error) {
          this.error = error;
        }
      } else {
        console.log("Please fill the forms correctly");
      }
    },
  },
};
</script>

<style></style>
