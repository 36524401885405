<template>
  <div v-if="!loading">
    <div class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="flex-grow px-5 mt-6 text-left sm:mt-0">
        <div class="mt-6 sm:text-left sm:mt-0">
          <h1 class="mb-3 text-2xl font-bold text-gray-700">
            {{ vacancy.jobTitle }}
          </h1>
          <div class="mb-2">
            <p class="inline-block mr-2 font-bold">Job Grade:</p>
            <span>{{ vacancy.jobGrade }}</span>
          </div>
          <div class="mb-2">
            <p class="inline-block mr-2 font-bold">Grade Salary:</p>
            <span>{{ vacancy.gradeSalary }}</span>
          </div>
          <div class="mb-4">
            <p class="inline-block mr-2 font-bold">Location:</p>
            <span>{{ vacancy.location }}</span>
          </div>
          <h2 class="mb-2 text-xl font-bold">Job Description</h2>
          <p class="leading-relaxed">
            <VueShowdown :markdown="vacancy.jobDescription" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img :src="loadingImage" class="w-64 m-auto" />
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import axios from "axios";

export default {
  name: "SelectedLatestVacancyDetail",
  data() {
    return {
      loading: true,
      vacancy: {},
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },

  async created() {
    if (this.$route.params.vacancy === undefined) {
      try {
        const response = await axios.get(`${baseUrl}/vacancies`);
        this.vacancy = response.data.find(
          (vacancy) =>
            vacancy.jobTitle.replace(/\s+/g, "-").toLowerCase() ===
            this.$route.params.jobTitle.replace(/\s+/g, "-").toLowerCase()
        );
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    } else {
      this.vacancy = this.$route.params.vacancy;
      this.loading = false;
    }
  },
};
</script>

<style></style>
