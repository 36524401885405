<template>
  <section>
    <slider
      animation="fade"
      :autoplay="true"
      :duration="5000"
      :speed="1000"
      :stopOnHover="true"
      :height="500 + 'px'"
    >
      <slider-item
        v-for="item in items"
        :key="item.id"
        :style="{
          backgroundImage: 'url(' + getBaseUrl() + item.sliderImage.url + ')',
          'object fit': 'cover',
          'background-repeat': 'no-repeat',
          'z-index': '2',
          'background-size': 'cover',
          'background-position': 'center',
          position: 'relative',
        }"
      >
        <div
          class="left-0 flex flex-col items-center justify-center h-full font-sans text-gray-200 bg-black md:items-start md:justify-end bg-opacity-40"
        >
          <div class="p-4 mx-8 text-center md:pb-32 md:pl-32">
            <p class="text-4xl font-bold text-gray-200 md:text-6xl">
              {{ item.sliderTitle }}
            </p>
            <p class="text-xl text-gray-100">{{ item.sliderContent }}</p>
          </div>
        </div>
      </slider-item>
      <!-- <div slot="loading">loading ...</div> -->
    </slider>
  </section>
</template>

<script>
import axios from "axios";
import { Slider, SliderItem } from "vue-easy-slider";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Hero",
  components: { Slider, SliderItem },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiHeros",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.slider-indicators {
  z-index: 10 !important;
}
.slider-indicator-icon {
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  margin: 0 0.1rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
}
.slider-indicator-active {
  background-color: #d76738 !important;
}
// .slider-btn-left, .slider-btn-right {
//    background-color: red !important;
// }
.slider-btn {
  z-index: 10 !important;
  height: 50px !important;
  top: 50% !important;
  transform: translateY(-50%);
  background: transparent !important;
}
.slider-btn-left {
  margin-left: 10px !important;
}
.slider-btn-right {
  margin-right: 10px !important;
}
.slider-icon {
  width: 30px !important;
  height: 30px !important;
  font-size: bold;
}
.slider-icon-left {
  margin-left: 10px !important;
}
.slider-icon-right {
  margin-right: 10px !important;
}
</style>
