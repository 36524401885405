<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <div class="px-5">
      <hr />
    </div>
    <div class="px-5 py-20" v-for="item in items" :key="item.id">
      <div class="order-1 md:order-2">
        <h1
          class="mb-6 text-4xl font-semibold tracking-tight text-center text-primary-orange text-primary-blue md:leading-tight sm:text-left md:text-4xl"
        >
          {{ item.Title }}
        </h1>
      </div>
      <div class="order-2 w-full h-full md:order-1">
        <video
          controls
          muted
          :src="getBaseUrl() + item.Video.url"
          class="w-full border rounded-sm border-primary-blue"
        ></video>
      </div>
    </div>
    <!-- <div v-for="item in items" :key="item.id" class="">
      <video
        controls
        muted
        :src="getBaseUrl() + item.Video.url"
        class="w-full"
      ></video>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Video",
  data: () => ({
    items: [],
  }),
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiVideos",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
