var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-6xl mx-auto text-gray-600"},[_vm._m(0),_c('section',[_c('div',{staticClass:"px-5 py-20 mx-auto"},[_c('div',{staticClass:"w-full mb-10 lg:w-1/2"},[_c('h2',{staticClass:"mb-2 text-2xl font-bold text-gray-900 sm:text-3xl"},[_vm._v(" "+_vm._s(_vm.$t("latest-news"))+" ")]),_c('div',{staticClass:"w-20 h-1 rounded bg-primary-green"})]),_c('div',{staticClass:"flex flex-wrap -mx-4 -mt-4 -mb-10 sm:-m-4"},_vm._l((_vm.latestNews),function(news){return _c('div',{key:news.id,staticClass:"p-4 mb-6 md:w-1/3 sm:mb-0"},[_c('div',{staticClass:"h-64 overflow-hidden rounded-lg"},[_c('img',{staticClass:"object-cover object-center w-full h-full",attrs:{"alt":"content","src":_vm.getBaseUrl() + news.newsImage.url}})]),_c('h2',{staticClass:"mt-5 text-xl font-bold text-gray-900 title-font"},[_vm._v(" "+_vm._s(news.newsTitle)+" ")]),_c('p',{staticClass:"mt-2 leading-relaxed",class:{
              'line-clamp-4':
                news.newsContent.trim().split(/\s+/).length > 28
                  ? true
                  : false,
            }},[_c('VueShowdown',{attrs:{"markdown":news.newsContent}})],1),(news.newsSource)?_c('p',{staticClass:"mt-2"},[_c('span',{staticClass:"italic font-medium"},[_vm._v("Source link: ")]),_c('a',{staticClass:"border-b border-transparent hover:text-primary-orange hover:border-primary-orange",attrs:{"href":news.newsSource,"target":"_blank"}},[_vm._v(_vm._s(news.newsSource))])]):_vm._e(),(
              news.newsContent.trim().split(/\s+/).length > 28 ? true : false
            )?_c('router-link',{staticClass:"inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange",attrs:{"to":{
              name: 'SelectedLatestNewsDetail',
              params: {
                news: news,
                newsTitle: news.newsTitle.replace(/\s+/g, '-').toLowerCase(),
              },
            }},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_vm._v(" Read More "),_c('svg',{staticClass:"w-4 h-4 ml-2",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])]):_vm._e()],1)}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5"},[_c('hr')])
}]

export { render, staticRenderFns }