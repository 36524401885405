<template>
  <div
    class="flex flex-col px-5 pb-10 mx-auto mb-10 text-gray-600 border-b border-gray-200 sm:flex-row"
  >
    <div class="flex-grow mt-6 text-left sm:mt-0">
      <h1 class="mb-3 text-2xl font-bold text-gray-700">
        {{ vacancy.jobTitle }}
      </h1>
      <div class="mb-2">
        <p class="inline-block mr-2 font-bold">Job Grade:</p>
        <span>{{ vacancy.jobGrade }}</span>
      </div>
      <div class="mb-2">
        <p class="inline-block mr-2 font-bold">Grade Salary:</p>
        <span>{{ vacancy.gradeSalary }}</span>
      </div>
      <div class="mb-4">
        <p class="inline-block mr-2 font-bold">Location:</p>
        <span>{{ vacancy.location }}</span>
      </div>
      <h2 class="mb-2 text-xl font-bold">Job Description</h2>
      <p
        class="leading-relaxed"
        :class="{
          'line-clamp-4':
            vacancy.jobDescription.trim().split(/\s+/).length > 60
              ? true
              : false,
        }"
      >
        <VueShowdown :markdown="`${vacancy.jobDescription}`" />
      </p>
      <router-link
        :to="{
          name: 'SelectedLatestVacancyDetail',
          params: {
            vacancy: vacancy,
            jobTitle: vacancy.jobTitle.replace(/\s+/g, '-').toLowerCase(),
          },
        }"
        @click.native="scrollToTop"
        class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
        v-if="
          vacancy.jobDescription.trim().split(/\s+/).length > 60 ? true : false
        "
      >
        View job details
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="w-4 h-4 ml-2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
export default {
  name: "SingleVacancyDisplay",
  props: ["vacancy"],
  data() {
    return {
      showMore: true,
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
