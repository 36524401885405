<template>
  <div v-if="!loading">
    <div class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="pb-10 mx-auto mb-10 lg:w-4/5 sm:flex-row">
        <div class="mb-6">
          <img
            class="object-cover object-center w-full h-full rounded lg:w-5/6 lg:h-96"
            :src="getBaseUrl() + news.newsImage.url"
            alt="samaritan"
          />
        </div>
        <div class="mt-6 sm:text-left sm:mt-0">
          <h2 class="text-xl font-semibold text-gray-900 title-font">
            {{ news.newsTitle }}
          </h2>
          <span class="text-sm text-gray-400">{{ news.publishedDate }}</span>
          <p class="mt-3 text-base leading-relaxed">
            <VueShowdown :markdown="news.newsContent" />
          </p>
          <p v-if="news.newsSource" class="mt-2">
            <span class="italic font-medium">Source link: </span>
            <a
              :href="news.newsSource"
              target="_blank"
              class="border-b border-transparent hover:text-primary-orange hover:border-primary-orange"
              >{{ news.newsSource }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img :src="loadingImage" class="w-64 m-auto" />
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import axios from "axios";

export default {
  name: "SelectedLatestNewsDetail",
  data() {
    return {
      loading: true,
      news: {},
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },

  async created() {
    if (this.$route.params.news === undefined) {
      try {
        const response = await axios.get(
          `${baseUrl}/latest-news?_locale=${localStorage.lang}`
        );
        this.news = response.data.find(
          (news) =>
            news.newsTitle.replace(/\s+/g, "-").toLowerCase() ===
            this.$route.params.newsTitle.replace(/\s+/g, "-").toLowerCase()
        );
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    } else {
      this.news = this.$route.params.news;
      this.loading = false;
    }
  },
};
</script>

<style></style>
