<template>
	<section class="overflow-hidden text-gray-600 body-font">
		<div class="container w-full px-5 py-20 mx-auto md:w-3/5 md:px-0">
			<div class="-my-8 divide-y-2 divide-gray-100">
				<div class="flex flex-wrap py-8 md:flex-nowrap">
					<div class="md:flex-grow">
						<h2 class="mb-2 text-xl font-bold text-gray-900 title-font">
							Imprint
						</h2>
						<p
							class="leading-relaxed"
							v-for="item in imprint"
							:key="item.id"
						>
							<VueShowdown :markdown="item.Content" />
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
	name: "Imprint",
	data: () => ({
		imprint: "",
	}),
	async mounted() {
		try {
			const response = await axios.get(`${baseUrl}/imprints`);
			this.imprint = response.data;

			this.loading = false;
		} catch (error) {
			this.error = error;
		}
	},
};
</script>

<style></style>
