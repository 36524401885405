<template>
  <div class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
    <div v-if="!loading">
      <div class="flex flex-wrap w-full mb-10">
        <div class="w-full mb-6 lg:w-1/2 lg:mb-0">
          <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
            {{ $t("our-team") }}
          </h1>
          <div class="w-20 h-1 rounded bg-primary-green"></div>
        </div>
      </div>
      <div
        class="flex flex-wrap mb-16"
        v-for="doctor in items"
        :key="doctor.id"
      >
        <div class="flex flex-col justify-center h-full md:flex-row">
          <img
            alt="team"
            class="flex-shrink-0 object-cover object-center h-64 mb-4 rounded-sm w-80 md:w-56"
            :src="getBaseUrl() + doctor.image.url"
          />
          <div class="flex-grow mt-2 md:pl-8">
            <h2 class="text-xl font-bold text-gray-900">
              {{ doctor.name }}
            </h2>
            <h3 class="mb-3 font-semibold">
              {{ doctor.specialization }}
            </h3>
            <div class="mb-4 leading-relaxed">
              <VueShowdown
                :markdown="doctor.profile !== undefined ? doctor.profile : ''"
              />
            </div>
            <a
              v-if="
                doctor.name === 'Dr. Samuel Hailu' &&
                doctor.id === '619216c6881dc80016ac9a3f' &&
                doctor.department.id === '6163e4ad55e2f500160c0aae'
              "
              href="https://drsamuelhailu.com/booking/"
              class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
            >
              {{ $t("book-an-appointment") }}
            </a>
            <a
              v-else
              href="/appointment"
              class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
            >
              {{ $t("book-an-appointment") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

import { mapGetters } from "vuex";
export default {
  name: "Doctors",
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiDoctors",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        let sorted = response.data.sort((a, b) => a.rank - b.rank);
        this.items = sorted;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
