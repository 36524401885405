import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Departments from "../views/Departments";
import News from "../views/News";
import Contact from "../views/Contact";
import Vacancy from "../views/Vacancy";
import Reviews from "../views/Reviews";
import Appointment from "../views/Appointment";
import Doctors from "../views/Doctors";
//import DoctorsHome from "../views/DoctorsHome.vue";
import Imprint from "../views/Imprint";
import PrivacyPolicy from "../views/PrivacyPolicy";
import SelectedLatestNewsDetail from "../components/SelectedLatestNewsDetail";
import SelectedLatestVacancyDetail from "../components/SelectedLatestVacancyDetail";
import SelectedDepartmentDetail from "../components/SelectedDepartmentDetail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/departments",
    name: "Departmnet",
    component: Departments,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/department/:title",
    name: "SelectedDepartmentDetail",
    component: SelectedDepartmentDetail,
  },
  {
    path: "/news/:newsTitle",
    name: "SelectedLatestNewsDetail",
    component: SelectedLatestNewsDetail,
  },
  {
    path: "/vacancy/:jobTitle",
    name: "SelectedLatestVacancyDetail",
    component: SelectedLatestVacancyDetail,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/vacancy",
    name: "Vacancy",
    component: Vacancy,
  },
  {
    path: "/reviews",
    name: "Review",
    component: Reviews,
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: Appointment,
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: Doctors,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
