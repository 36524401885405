<template>
  <div class="py-20">
    <div class="flex flex-wrap md:flex-nowrap" v-for="v in vission" :key="v.id">
      <div class="flex flex-col flex-shrink-0 mb-6 md:w-64 md:mb-0">
        <span class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
          {{ v.Title }}
        </span>
      </div>
      <div class="md:flex-grow">
        <p class="leading-relaxed">
          <VueShowdown :markdown="v.Content" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Vission",
  data() {
    return {
      vission: [],
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiVissions",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.vission = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
