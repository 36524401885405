<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <div class="px-5">
      <hr />
    </div>
    <section>
      <div class="px-5 py-20 mx-auto">
        <div class="w-full mb-10 lg:w-1/2">
          <h2 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
            {{ $t("latest-news") }}
          </h2>
          <div class="w-20 h-1 rounded bg-primary-green"></div>
        </div>
        <div class="flex flex-wrap -mx-4 -mt-4 -mb-10 sm:-m-4">
          <div
            class="p-4 mb-6 md:w-1/3 sm:mb-0"
            v-for="news in latestNews"
            :key="news.id"
          >
            <div class="h-64 overflow-hidden rounded-lg">
              <img
                alt="content"
                class="object-cover object-center w-full h-full"
                :src="getBaseUrl() + news.newsImage.url"
              />
            </div>
            <h2 class="mt-5 text-xl font-bold text-gray-900 title-font">
              {{ news.newsTitle }}
            </h2>
            <p
              class="mt-2 leading-relaxed"
              :class="{
                'line-clamp-4':
                  news.newsContent.trim().split(/\s+/).length > 28
                    ? true
                    : false,
              }"
            >
              <VueShowdown :markdown="news.newsContent" />
            </p>
            <p v-if="news.newsSource" class="mt-2">
              <span class="italic font-medium">Source link: </span>
              <a
                :href="news.newsSource"
                target="_blank"
                class="border-b border-transparent hover:text-primary-orange hover:border-primary-orange"
                >{{ news.newsSource }}</a
              >
            </p>
            <router-link
              :to="{
                name: 'SelectedLatestNewsDetail',
                params: {
                  news: news,
                  newsTitle: news.newsTitle.replace(/\s+/g, '-').toLowerCase(),
                },
              }"
              @click.native="scrollToTop"
              class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
              v-if="
                news.newsContent.trim().split(/\s+/).length > 28 ? true : false
              "
            >
              Read More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "News",
  data() {
    return {
      showMore: true,
      latestNews: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiLatestNews",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },

    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        let sorted = response.data
          .slice()
          .sort(
            (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
          );
        this.latestNews = sorted.splice(0, 3);

        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
