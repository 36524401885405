<template>
	<div>
		<Hero></Hero>
		<Our-community></Our-community>
		<Video></Video>
		<Affiliate></Affiliate>
		<LatestNews></LatestNews>
		<Subscribe></Subscribe>
	</div>
</template>

<script>
import Hero from "../components/Hero.vue";
import OurCommunity from "../components/OurCommunity.vue";
import Video from "../components/Video.vue";
import Affiliate from "../components/Affiliate.vue";
import LatestNews from "../components/LatestNews.vue";
import Subscribe from "../components/Subscribe.vue";

// @ is an alias to /src

export default {
	name: "Home",
	components: {
		OurCommunity,
		Hero,
		Video,
		Affiliate,
		Subscribe,
		LatestNews,
	},
};
</script>
<style></style>
