<template>
  <section class="relative px-5 py-20 mx-auto text-gray-600 md:px-0 mx-w-7xl">
    <div class="w-full mx-auto mb-10 md:w-3/5">
      <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl title-font">
        {{ $t("book-an-appointment") }}
      </h1>
      <div class="w-20 h-1 rounded bg-primary-green"></div>
    </div>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <div class="mx-auto md:w-3/5">
          <div class="flex flex-wrap">
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="department"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("departments") }}
                </label>
                <ValidationProvider
                  name="Departments"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    name="departments"
                    v-model="department"
                    id="departments"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  >
                    <option value="">Select department</option>
                    <option
                      v-for="department in departments"
                      :key="department.id"
                      :value="department"
                    >
                      {{ department.title }}
                    </option>
                  </select>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="doctors"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("doctors") }}
                </label>
                <ValidationProvider
                  name="Doctors"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    :disabled="disabled"
                    ref="docRef"
                    name="doctors"
                    id="doctors"
                    v-model="doctor"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  >
                    <option value="">Select doctor</option>
                    <option
                      v-for="doctor in doctors"
                      :key="doctor.id"
                      :value="doctor"
                    >
                      {{ doctor.name }}
                    </option>
                  </select>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="Adate"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("appointment-date") }}
                </label>
                <ValidationProvider
                  name="Appointment date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <VueCtkDateTimePicker
                    v-model="appointmentDate"
                    :disabled-weekly="disabledWeekly"
                    :label="$t('select-date')"
                    :only-date="true"
                    format="YYYY-MM-DD"
                    formatted="YYYY-DD-MM"
                    right
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="Atime"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                  >{{ $t("time") }}</label
                >
                <ValidationProvider
                  name="Time"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <!-- <VueCtkDateTimePicker v-model="time" label="Select time" :only-time="true"
                              format="HH:mm A" formatted="LT" output-format="hh:mm A" :disabled-hours="availableHours"
                            >
                            </VueCtkDateTimePicker> -->
                  <select
                    name="time"
                    id="time"
                    v-model="time"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  >
                    <option value="">{{ $t("select-time") }}</option>
                    <option
                      v-for="hour in appointMentHours"
                      :key="hour.id"
                      :value="hour"
                    >
                      {{ hour }}
                    </option>
                  </select>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="flex justify-center w-full p-2 my-8 rounded bg-gray-50 item-center"
            >
              <div class="p-4">
                <span class="block mb-1 text-sm font-semibold text-gray-600">{{
                  $t("type-of-appointment")
                }}</span>
                <div class="mt-2">
                  <ValidationProvider
                    name="Type Of Appointment"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label class="inline-flex items-center">
                      <input
                        v-model="appointmentType"
                        type="radio"
                        class="w-4 h-4 form-radio"
                        name="appointmentType"
                        value="new"
                      />
                      <span class="ml-2 text-sm">{{
                        $t("new-appointment")
                      }}</span>
                    </label>
                    <label class="inline-flex items-center ml-6">
                      <input
                        v-model="appointmentType"
                        type="radio"
                        class="w-4 h-4 form-radio"
                        name="Appointment Type"
                        value="followUp"
                      />
                      <span class="ml-2 text-sm">{{ $t("follow-up") }}</span>
                    </label>
                    <div class="text-sm text-red-600">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="fName"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("first-name") }}
                </label>
                <ValidationProvider
                  name="First name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="fName"
                    v-model="fName"
                    name="firstName"
                    :placeholder="$t('first-name')"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="lName"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("last-name") }}
                </label>
                <ValidationProvider
                  name="Last name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="lName"
                    v-model="lName"
                    name="lastName"
                    :placeholder="$t('last-name')"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="age"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                  >{{ $t("age") }}</label
                >
                <ValidationProvider
                  name="Age"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <input
                    type="number"
                    id="Age"
                    v-model="age"
                    name="age"
                    :placeholder="$t('age')"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="email"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("email") }}
                </label>
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    id="email"
                    v-model="email"
                    name="email"
                    :placeholder="$t('email')"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="w-full p-2 mb-2 sm:w-1/2">
              <div class="relative">
                <label
                  for="phone"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("phone-number") }}
                </label>
                <ValidationProvider
                  name="Phone number"
                  :rules="{
                    required: true,
                    regex: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                  }"
                  v-slot="{ errors }"
                >
                  <input
                    type="tel"
                    id="phone"
                    v-model="phone"
                    name="phone"
                    :placeholder="$t('phone-number')"
                    class="w-full px-3 py-1 leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2">
              <div class="relative">
                <label
                  for="message"
                  class="block mb-1 text-sm font-semibold text-gray-600"
                >
                  {{ $t("reason-for-your-appointment") }}
                </label>
                <ValidationProvider
                  name="Reason for your visit"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <textarea
                    id="message"
                    v-model="message"
                    name="message"
                    :placeholder="$t('reason-for-your-appointment')"
                    class="w-full h-32 px-3 py-2 leading-6 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-300 rounded-sm outline-none resize-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                  >
                  </textarea>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="w-full p-2">
              <button
                :loading="loading"
                type="submit"
                class="w-full px-6 py-3 mx-auto text-white transition-colors duration-200 ease-in-out border-0 rounded md:justify-center md:flex md:w-1/4 bg-primary-orange focus:outline-none hover:bg-yellow-700"
              >
                {{ $t("book-an-appointment") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Appointment",
  data() {
    return {
      error: "",
      disabled: true,
      departments: [],
      doctors: [],
      appointments: [],
      availableHours: [],
      valid: true,
      fName: "",
      lName: "",
      phone: "",
      age: null,
      doctor: {},
      department: {},
      appointmentDate: "",
      disabledWeekly: [],
      time: "",
      email: "",
      message: "",
      date: new Date(),
      loading: false,
      appointmentType: "",
      formAppointments: ["Video Call", "In Person"],
      formOfAppointment: "",
      snackbar: false,
      snackbar_message: "",
      today: new Date(),
      daysOfWeek: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      afternoonHours: ["02:00", "02:30", "03:00", "03:30", "04:00", "04:30"],
      morningHours: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
      ],
      appointMentHours: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      apiDo: "LanguageStore/apiDoctors",
      apiDe: "LanguageStore/apiDepartments",
    }),
  },
  async created() {
    await this.getData();
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
    department: function () {
      this.disabled = this.department === null;
      this.doctors = this.department ? this.department.doctors : [];
    },
    doctor: function () {
      if (
        (this.doctor.name === "Dr. Samuel Hailu") &
        (this.doctor.id === "619216c6881dc80016ac9a3f")
      ) {
        location.assign("https://drsamuelhailu.com/booking/");
        return;
      }
      if (this.doctor !== null) {
        this.disabledWeekly = [];
        if (
          this.doctor.workingDays.length &&
          this.doctor.workingDays[0].day !== "allDays"
        ) {
          let availableDays = this.doctor.workingDays.map((day) => {
            return day.day;
          });
          let disabledIndex = [];
          for (let i = 0; i < 7; i++) {
            if (!availableDays.includes(this.daysOfWeek[i]))
              disabledIndex.push(i);
          }
          this.disabledWeekly = disabledIndex;
        }
      }
    },
    appointmentDate: function () {
      let shift;
      if (!this.doctor.workingDays.length) {
        shift = "wholeDay";
      } else if (this.doctor.workingDays[0].day === "allDays") {
        shift = this.doctor.workingDays[0].shift;
      } else {
        const dayIndex = new Date(this.appointmentDate).getDay();
        const selectedDay = this.doctor.workingDays.filter((day) => {
          return day.day === this.daysOfWeek[dayIndex];
        });
        shift = selectedDay[0].shift;
      }
      if (shift === "morning") {
        let hours = this.filterDuplicateAppointments([...this.morningHours]);
        let appointMentHours = hours.map((h) => {
          return `${h} AM`;
        });
        this.appointMentHours = appointMentHours;
      } else if (shift === "afternoon") {
        let hours = this.filterDuplicateAppointments([...this.afternoonHours]);
        let appointMentHours = hours.map((h) => {
          return `${h} PM`;
        });
        this.appointMentHours = appointMentHours;
      } else {
        let morningHours = this.filterDuplicateAppointments([
          ...this.morningHours,
        ]);
        morningHours = morningHours.map((h) => {
          return `${h} AM`;
        });
        let afternoonHours = this.filterDuplicateAppointments([
          ...this.afternoonHours,
        ]);
        afternoonHours = afternoonHours.map((h) => {
          return `${h} PM`;
        });
        this.appointMentHours = [...morningHours, ...afternoonHours];
      }
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const departments = await axios.get(this.apiDe);
        this.departments = departments.data;

        const doctors = await axios.get(this.apiDo);
        this.doctors = doctors.data;

        const appointments = await axios.get(`${baseUrl}/appointments`);
        this.appointments = appointments.data;

        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
    showToast() {
      this.$toast.success("Appointment sent successfully");
    },
    filterDuplicateAppointments(hours) {
      let same_day_appointments = this.appointments.filter((appointment) => {
        return (
          appointment.doctor &&
          appointment.doctor.id === this.doctor.id &&
          appointment.appointmentDate === this.appointmentDate
        );
      });
      for (let i = 0; i < same_day_appointments.length; i++) {
        hours = hours.filter((h) => {
          return same_day_appointments[i].appointmentTime !== `${h}:00.000`;
        });
      }
      return hours;
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          let appointment_time = this.time.slice(0, -3);
          axios
            .post(`${baseUrl}/appointments`, {
              patientFirstName: this.fName,
              patientLastName: this.lName,
              patientPhone: this.phone,
              patientAge: parseInt(this.age),
              appointmentType: this.appointmentType,
              doctorName: this.doctor.name,
              doctor: this.doctor.id,
              department: this.department.title,
              appointmentDate: this.appointmentDate,
              appointmentTime: appointment_time.concat(":00"),
              patientEmail: this.email,
              reasonForVisit: this.message,
            })
            .then(function (response) {
              console.log(response.status);
            })
            .catch(function (error) {
              this.error = error;
            });
          this.showToast();
        } catch (error) {
          console.log({ error });
        }
      } else {
        console.log("Please fill the forms correctly");
      }
    },
  },
};
</script>

<style></style>
